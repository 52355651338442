import React from 'react';
import { graphql } from 'gatsby';


import Layout from '../components/layout';
import SEO from '../components/seo';
import AboutUsPage from '../components/aboutUsPage';


class AboutUS extends React.Component {
  render() {

    const { data } = this.props;
    const { testimonials } = data.homeDeJson;
    const { seo, milestones, intro, content } = data.aboutUsDeJson;


    return (
      <Layout data={this.props.data} location={this.props.location}>
        <SEO {...seo} />

        <AboutUsPage
          headerImage={data.file.childImageSharp.fixed}
          activeLangKey={this.props.pageContext.langKey}
          testimonials={testimonials}
          milestones={milestones}
          intro={intro}
          content={content}
        />

      </Layout>
    );
  }
}


export default AboutUS;

export const pageQuery = graphql`
    query AbeutUsDeQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        file(relativePath: { eq: "header-bg-medis.png" }) {
            childImageSharp {
                fixed(width: 2500 height: 793 quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        homeDeJson {
            testimonials {
                name
                job
                blockquote
                image {
                    childImageSharp {
                        fixed(width: 100, height: 100, quality: 100) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
        aboutUsDeJson {
            seo {
                title
                description
                lang
                keywords
            }
            intro {
                preHeader
                header
            }
            content {
                preHeader
                header
                contentOne
                contentTwo
            }
            milestones {
                date
                content
            }
            professionalsSay {
                name
                job
                msg
                image {
                    childImageSharp {
                        fixed(width: 60, height: 60, quality: 100) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
    }
`;
